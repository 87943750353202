/* eslint-disable react-hooks/exhaustive-deps */
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Button,
  type ButtonProps,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { type FC, type PropsWithChildren, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ValidationTarget } from "..";
import { FormTab } from "../form-tab";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnsafeFormTab = FormTab<any, any, any>;

export type FormNavigationEntry = {
  className?: string;
  label: string;
  path: string;
  slot?: React.ReactNode;
};

export type FormNavigationAction =
  | "cancel"
  | "save"
  | "save-and-back"
  | "save-and-close"
  | "save-and-next"
  | "delete"
  | "confirm-up-to-date";
export type FormNavigationActionConfig = {
  onClick: (() => void) | (() => Promise<void>);
  key: FormNavigationAction;
  label: string;
  visible: boolean;
  overrideValidationTarget?: ValidationTarget;
  isCheckBoxChecked?: boolean;
  addCheckBoxToForm?: boolean;
} & ButtonProps;

export type FormNavigationWrapperProps = {
  actions: FormNavigationActionConfig[][];
  activeNavigationEntry?: FormNavigationEntry;
  activeTab?: string;
  inDialog?: boolean;
  navigationEntries?: FormNavigationEntry[];
  navigationSlot?: React.ReactNode;
  onTabSelected: (tabLabel: string) => void;
  onNavigate?: (path: string) => void;
  tabs: UnsafeFormTab[];
  title?: string;
};

export const FormNavigationWrapper: FC<
  PropsWithChildren<FormNavigationWrapperProps>
> = ({
  actions,
  activeNavigationEntry,
  activeTab,
  children,
  inDialog,
  navigationEntries,
  navigationSlot,
  onTabSelected,
  onNavigate,
  tabs,
  title,
}) => {
  const [left, right] = actions;
  const titleHeight = inDialog ? 60 : 0;

  return (
    <Box
      sx={{
        height: !inDialog ? "calc(100vh - 130px)" : "100%",
      }}
    >
      {inDialog && (
        <Box
          sx={{
            height: `${titleHeight}px`,
            display: "flex",
            alignItems: "center",
            pt: "12px",
            pb: "12px",
            pl: "24px",
            borderBottom: "#B8D8DD 2px solid",
          }}
        >
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: !inDialog
            ? "calc(100vh - 130px)"
            : `calc(100% - ${titleHeight}px)`,
          padding: 0,
          margin: 0,
        }}
      >
        <Box
          sx={{
            background: "#DFEFF2",
            display: "flex",
            flex: "0 0 340px",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "44px 40px",
          }}
        >
          <Box
            sx={{
              overflow: "auto",
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            {navigationEntries && navigationEntries.length > 0
              ? navigationEntries?.map(({ className, label, path, slot }) => {
                  const isActive = path === activeNavigationEntry?.path;

                  return (
                    <div key={path} className={className}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <NavigationEntry
                          path={path}
                          label={label}
                          isActive={isActive}
                          onNavigate={onNavigate}
                        />
                        {slot}
                      </Box>

                      {isActive &&
                        tabs.map((tab) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                              key={tab.label}
                            >
                              <SecondLevelTab
                                tab={tab}
                                activeTab={activeTab}
                                onTabSelected={onTabSelected}
                              />
                              {tab.hasErrors && tab.validationIndicatorSlot}
                            </Box>
                          );
                        })}
                    </div>
                  );
                })
              : tabs.map((tab) => {
                  return (
                    <FirstLevelTab
                      tab={tab}
                      key={tab.label}
                      activeTab={activeTab}
                      onTabSelected={onTabSelected}
                    />
                  );
                })}
          </Box>
          {navigationSlot && <Box>{navigationSlot}</Box>}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box
            sx={{
              padding: "46px",
              overflowY: "auto",
              flex: "1 1 100%",
            }}
          >
            {children}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "12px 46px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
              }}
            >
              {left.map(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                ({ key, overrideValidationTarget, visible, ...action }) =>
                  visible && (
                    <Button key={key} {...action}>
                      {action.label}
                    </Button>
                  )
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
              }}
            >
              {right.map(
                ({
                  key,
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  overrideValidationTarget,
                  visible,
                  isCheckBoxChecked,
                  ...action
                }) =>
                  visible &&
                  (key === "confirm-up-to-date" ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={action.onClick}
                          color="primary"
                          checked={isCheckBoxChecked}
                        />
                      }
                      label={action.label}
                      labelPlacement="start"
                      key={key}
                    />
                  ) : (
                    <Button key={key} {...action}>
                      {action.label}
                    </Button>
                  ))
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

function NavigationEntry({
  path,
  label,
  isActive,
  onNavigate,
}: {
  path: string;
  label: string;
  isActive: boolean;
  onNavigate?: (path: string) => void;
}) {
  const Wrapper = onNavigate
    ? ({ children }: PropsWithChildren) => (
        <div
          onClick={() => onNavigate(path)}
          onKeyDown={() => onNavigate(path)}
          role="button"
          tabIndex={0}
        >
          {children}
        </div>
      )
    : ({ children }: PropsWithChildren) => (
        <Link to={path} style={{ textDecoration: "none" }}>
          {children}
        </Link>
      );

  return (
    <Wrapper>
      <Box
        sx={{
          borderLeft: "#B2CED4 2px solid",
          color: `${isActive ? "#003781" : "#000000"}`,
          cursor: "pointer",
          fontSize: "16px",
          fontWeight: isActive ? 600 : 400,
          padding: "12px 4px 12px 11px",
          transition: "border-left-color 0.3s",
        }}
      >
        {label}
      </Box>
    </Wrapper>
  );
}

function FirstLevelTab({
  tab,
  activeTab,
  onTabSelected,
}: {
  tab: UnsafeFormTab;
  activeTab?: string;
  onTabSelected: (label: string) => void;
}) {
  const [areChildrenExpanded, setChildrenExpanded] = useState(false);

  useEffect(() => {
    setChildrenExpanded(
      activeTab === tab.label ||
        tab.childTabs.map((tab) => tab.label).includes(activeTab ?? "")
    );
  }, [activeTab]);

  return (
    <>
      <Box
        onClick={() => onTabSelected(tab.label)}
        sx={{
          cursor: "pointer",
          height: "28px",
          fontSize: "16px",
          fontWeight: activeTab === tab.label ? 600 : 400,
          borderLeft: `${
            activeTab === tab.label ? "#003781" : "#B2CED4"
          } 2px solid`,
          color: `${activeTab === tab.label ? "#003781" : "#000000"}`,
          padding: "32px 4px 32px 11px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          transition: "border-left-color 0.3s",
        }}
      >
        {tab.label}
        {tab.childTabs?.length > 0 && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setChildrenExpanded(!areChildrenExpanded);
            }}
          >
            {areChildrenExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          maxHeight: areChildrenExpanded ? "200px" : 0,
          transition: "max-height 1s",
          overflow: "hidden",
        }}
      >
        {tab.childTabs?.map((childTab) => (
          <SecondLevelTab
            tab={childTab}
            key={childTab.label}
            onTabSelected={onTabSelected}
            activeTab={activeTab}
          />
        ))}
      </Box>
    </>
  );
}

function SecondLevelTab({
  tab,
  activeTab,
  onTabSelected,
}: {
  tab: UnsafeFormTab;
  activeTab?: string;

  onTabSelected: (label: string) => void;
}) {
  return (
    <Box
      onClick={() => onTabSelected(tab.label)}
      sx={{
        cursor: "pointer",
        height: "10px",
        fontSize: "14px",
        fontWeight: activeTab === tab.label ? 600 : 400,
        borderLeft: `${
          activeTab === tab.label ? "#003781" : "#B2CED4"
        } 2px solid`,
        color: `${activeTab === tab.label ? "#003781" : "#000000"}`,
        padding: "16px 4px 16px 24px",
        display: "flex",
        alignItems: "center",
        transition: "border-left-color 0.3s",
      }}
    >
      {tab.label}
    </Box>
  );
}
