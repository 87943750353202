/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExpandLess, ExpandMore, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  BoxProps,
  IconButton,
  Popover,
  SxProps,
  Table as MuiTable,
  Typography,
  useTheme,
} from "@mui/material";
import {
  DefaultContainerComponent,
  MapDefinitionToProps,
  Markdown,
  PimoClassComponent,
  PimoReactComponent,
  ReportTableCell,
  ReportTableHeader,
  ReportTableRow,
  TableDefinition,
} from "@pimo/pimo-app-builder";
import { SyntheticEvent, useState } from "react";

export type PimoTableFilterEventPayload<
  T extends Record<string, unknown> = Record<string, unknown>,
> = { filter?: T };
export type PimoTableSortEventPayload<T extends string = string> = {
  sort: { key: T };
};
export type SortDirection = "ASC" | "DESC";
export type PimoTableEventNames = "sort" | "filter";
export type PimoTableEventPayload =
  | PimoTableFilterEventPayload
  | PimoTableSortEventPayload;

type PimoTableRowProps = {
  onClick?: (event: SyntheticEvent) => void;
  cardProps?: BoxProps;
};

type PimoTableProps = {
  container?: React.FC<any>;
  tableHeaderEntries?: {
    label: string;
    fieldName: string;
    sort: SortDirection | null;
    sx?: SxProps;
    isHidden?: boolean;
  }[];
  title?: string;
  infoIconText?: string;
};

type DeriveDefinition<Component> =
  Component extends PimoTable<infer Definition> ? Definition : never;

export type DerivePimoTableProps<Component> = {
  data: {
    columnProps: MapDefinitionToProps<DeriveDefinition<Component>>;
    rowProps: PimoTableRowProps;
  }[];
} & PimoTableProps;

export class PimoTable<
  Definition extends TableDefinition,
> extends PimoClassComponent<any, PimoTableEventNames, PimoTableEventPayload> {
  constructor(private componentsInColumns: Definition) {
    super();
  }

  render(): PimoReactComponent<
    any,
    PimoTableEventNames,
    PimoTableEventPayload
  > {
    return ({
      container = DefaultContainerComponent,
      containerProps,
      data,
      tableHeaderEntries,
      title,
      infoIconText,
      fireEvent,
    }: {
      fireEvent?: (
        eventName: PimoTableEventNames,
        payload: PimoTableEventPayload
      ) => void;
      containerProps?: React.ComponentProps<typeof container>;
      data: {
        columnProps: PimoTable<Definition>["componentsInColumns"];
        rowProps: PimoTableRowProps;
      }[];
    } & PimoTableProps) => {
      const Container = container;
      const theme = useTheme();
      const [infoIconPopoverAnchor, setInfoIconPopoverAnchor] =
        useState<HTMLButtonElement | null>(null);

      return (
        <Container
          sx={{
            boxShadow: "none",
            borderRadius: "12px",
          }}
          fireEvent={fireEvent}
          {...containerProps}
        >
          {title && (
            <Box
              sx={{
                alignItems: "center",

                borderBottom: "#DFEFF2 1px solid",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                p: 2,
              }}
            >
              <Typography variant="h5">{title}</Typography>
              {infoIconText && (
                <>
                  <Popover
                    open={!!infoIconPopoverAnchor}
                    anchorEl={infoIconPopoverAnchor}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    onClose={() => setInfoIconPopoverAnchor(null)}
                  >
                    <Typography
                      component="div"
                      sx={{ maxWidth: "500px", px: 2 }}
                    >
                      <Markdown>{infoIconText}</Markdown>
                    </Typography>
                  </Popover>
                  <IconButton
                    onClick={(e) =>
                      setInfoIconPopoverAnchor(e.target as HTMLButtonElement)
                    }
                  >
                    <InfoOutlined />
                  </IconButton>
                </>
              )}
            </Box>
          )}
          <MuiTable>
            <ReportTableHeader>
              <ReportTableRow>
                {tableHeaderEntries
                  ?.filter((item) => {
                    return !item.isHidden;
                  })
                  .map(({ sort, fieldName, label, sx }, i) => (
                    <ReportTableCell
                      sx={{ cursor: "pointer" }}
                      key={i}
                      onClick={() =>
                        fireEvent?.("sort", {
                          sort: {
                            key: fieldName,
                          },
                        })
                      }
                    >
                      <Box
                        sx={{
                          ...sx,
                          display: "flex",
                          color: theme.palette.primary.contrastText,
                        }}
                      >
                        {label}

                        {sort ? (
                          sort === "ASC" ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )
                        ) : (
                          <Box sx={{ width: "24px", height: "24px" }}></Box>
                        )}
                      </Box>
                    </ReportTableCell>
                  ))}
              </ReportTableRow>
            </ReportTableHeader>
            <tbody>
              {data.map(({ columnProps, rowProps }, index) => {
                const { cardProps, ...props } = rowProps;

                return (
                  <ReportTableRow
                    {...props}
                    key={index}
                    sx={{ ...(cardProps?.sx ?? {}) }}
                  >
                    {columnProps.map(
                      (
                        props: {
                          isHidden?: boolean;
                          component: PimoReactComponent<any, any>;
                        },
                        index
                      ) => {
                        const ComponentForColumn =
                          this.componentsInColumns[index]?.component ??
                          (() => <></>);
                        if (props.isHidden) {
                          return;
                        }
                        return (
                          <ReportTableCell key={index}>
                            <ComponentForColumn
                              {...props}
                              /** Reset the padding applied by `OverviewTableCell` */
                              cardProps={{ sx: { padding: 0 } }}
                            />
                          </ReportTableCell>
                        );
                      }
                    )}
                  </ReportTableRow>
                );
              })}
            </tbody>
          </MuiTable>
        </Container>
      );
    };
  }
}
