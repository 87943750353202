import { Box } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { TitleCard } from "@pimo/pimo-components";
import { FilterData, FilterOptions, PropertyFilters } from "in-types";

import {
  OverviewTitleCardSearchBarSlot,
  OverviewTitleCardSearchBarSlotProps,
} from "./overview-title-card-search-bar-slot.js";

export type OverviewTitleCardProps = {
  title?: string;
  filterOptions: FilterOptions;
  filterData: FilterData;
};

const handleFilterChange = (
  trigger: keyof PropertyFilters,
  filterData: FilterData,
  event: React.ChangeEvent<HTMLInputElement>,
  fireEvent?: (
    event: "filter:clear" | "filter:apply",
    payload?: FilterData
  ) => void
) => {
  if (!Array.isArray(filterData[trigger])) return;

  const filterArray = filterData[trigger];
  if (event.target.checked) {
    filterArray.push(event.target.name);
  } else {
    filterData[trigger] = filterArray.filter(
      (uncheckedValue) => uncheckedValue !== event.target.name
    ) as unknown as (typeof filterData)[typeof trigger];
  }

  fireEvent?.("filter:apply", filterData);
};

export const OverviewTitleCard: PimoReactComponent<
  OverviewTitleCardProps,
  "filter:clear" | "filter:apply",
  FilterData
> = ({ title, filterData, filterOptions, fireEvent, ...props }) => {
  return (
    <TitleCard
      title={title || "OE Overview"}
      endSlot={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <OverviewTitleCardSearchBarSlot
            {...(props as OverviewTitleCardSearchBarSlotProps)}
            clearAllFilters={() => fireEvent?.("filter:clear")}
            filterData={filterData}
            filterOptions={filterOptions}
            handleFilterChange={(event, trigger) => {
              handleFilterChange(
                trigger as keyof PropertyFilters,
                filterData,
                event,
                fireEvent
              );
            }}
            handleSearchQueryChange={(value) => {
              filterData.searchQueryFilter = value;
              fireEvent?.("filter:apply", filterData);
            }}
          />
        </Box>
      }
    />
  );
};
