import { App, View } from "@pimo/pimo-app-builder";
import {
  ArtefactsProgressCard,
  DonutChartCard,
  InformationCard,
  NumberCard,
  SectionHeader,
  TabsLayoutProps,
  TitleCard,
} from "@pimo/pimo-components";
import { AggregatedImpactValues, INITIATIVE_PHASES } from "in-types";
import { ENABLERS, getQuarterAndYear, IN_COLORS } from "in-utils";
import { generatePath } from "react-router-dom";

import { LineChartCard } from "../../../components/line-chart-card/line-chart-card.tsx";
import { InAppState } from "../../app.tsx";
import { APP_ROUTES } from "../../constants.ts";
import { convertToMillion, setOpacity } from "../../utils/index.ts";

export function buildDashboardView(
  view: View<InAppState, TabsLayoutProps>,
  app: App<InAppState>
) {
  const viewTitle = view.addComponent({
    component: TitleCard,
    layoutProps: {
      xs: 12,
      viewname: "dashboard",
    },
  });

  const topCardLayoutProps = {
    xs: 6,
    md: 12 / 3,
    lg: 12 / 6,
    viewname: "dashboard",
  };

  viewTitle.mapState((appState) => {
    const [currentQuarter, currentYear] = getQuarterAndYear();
    const currentOEName = appState.currentOE?.operatingEntity;

    return {
      title: `Dashboard - ${
        currentOEName ?? ""
      } (${currentQuarter}/${currentYear})`,
    };
  });

  const totalImpactValueCard = view.addComponent({
    component: NumberCard,
    layoutProps: topCardLayoutProps,
  });

  totalImpactValueCard.mapState((appState) => {
    const totalImpact = appState.currentOEInitiatives?.totalImpactValue;
    const totalImpactValue = totalImpact?.value ?? 0;
    const targetYear = totalImpact?.targetYear ?? "";

    return {
      title: "Total Impact EOY " + targetYear,
      number: convertToMillion(totalImpactValue) + " Mn",
      iconSrc: "checklist.svg",
    };
  });

  const overallProgressCard = view.addComponent({
    component: ArtefactsProgressCard,
    layoutProps: topCardLayoutProps,
  });

  overallProgressCard.mapState((appState) => {
    const updateProgress =
      appState.currentOEInitiatives?.updateStatusOverview?.updateProgress ?? 0;

    return {
      cardTitle: "Overall Progress",
      color: IN_COLORS.lightBlue,
      value: updateProgress,
      displayValue: updateProgress.toString() + "%",
      cardIcon: "checklist.svg",
    };
  });

  const totalInitiativesCard = view.addComponent({
    component: NumberCard,
    layoutProps: topCardLayoutProps,
  });

  totalInitiativesCard.mapState((appState) => {
    const numberOfInitiatives =
      appState.currentOEInitiatives?.updateStatusOverview
        ?.numberOfInitiatives ?? "";

    return {
      title: "Total Initiatives",
      number: numberOfInitiatives,
    };
  });

  const updatesCompletedCard = view.addComponent({
    component: NumberCard,
    layoutProps: topCardLayoutProps,
  });

  updatesCompletedCard.mapState((appState) => {
    const updateStatus =
      appState.currentOEInitiatives?.updateStatusOverview?.updateStatus ?? {};

    return {
      title: "Initiative Updates Complete",
      number: updateStatus.completed,
    };
  });

  const updatePendingCard = view.addComponent({
    component: NumberCard,
    layoutProps: topCardLayoutProps,
  });

  updatePendingCard.mapState((appState) => {
    const updateStatus =
      appState.currentOEInitiatives?.updateStatusOverview?.updateStatus ?? {};

    return {
      title: "Initiative Updates Pending",
      number: updateStatus.pending,
    };
  });

  const updatesOverdueCard = view.addComponent({
    component: NumberCard,
    layoutProps: topCardLayoutProps,
  });

  updatesOverdueCard.mapState(({ currentOEInitiatives }) => {
    const updateStatus =
      currentOEInitiatives?.updateStatusOverview?.updateStatus ?? {};

    return {
      title: "Initiative Updates Overdue",
      number: updateStatus?.overdue,
    };
  });

  const enablerCard = view.addComponent({
    component: InformationCard,
    layoutProps: {
      xs: 12,
      md: 12 / 2,
      viewname: "dashboard",
    },
  });

  enablerCard.mapState((appState) => {
    const { enablerOverview } = appState.currentOEInitiatives;

    return {
      title: "Enabler",
      progressBars: [
        {
          title: ENABLERS.AUTOMATION,
          progress: enablerOverview?.[ENABLERS.AUTOMATION]?.progress ?? 0,
          actualValue: enablerOverview?.[ENABLERS.AUTOMATION]?.value ?? "",
        },
        {
          title: ENABLERS.PROCESS_MINING,
          progress: enablerOverview?.[ENABLERS.PROCESS_MINING]?.progress ?? 0,
          actualValue: enablerOverview?.[ENABLERS.PROCESS_MINING]?.value ?? "",
        },
      ],
    };
  });

  const initiativePhasesCard = view.addComponent({
    component: DonutChartCard,
    layoutProps: {
      xs: 12,
      md: 12 / 2,
      viewname: "dashboard",
    },
  });

  initiativePhasesCard.mapState((appState) => {
    const { phasesOverview } = appState.currentOEInitiatives;

    return {
      title: "Initiative Phases",
      series: phasesOverview
        ? Object.values(phasesOverview as { [initiativePhase: string]: number })
        : [0, 0, 0, 0],
      backgroundColor: [
        IN_COLORS.grey,
        IN_COLORS.yellow,
        IN_COLORS.green,
        IN_COLORS.deepBlue,
      ],
      labels: INITIATIVE_PHASES,
      toolTipEnabled: true,
      toolTipLabels: INITIATIVE_PHASES,
      onSectionClickHandler(_, __, config: { dataPointIndex: number }) {
        const sectionName = INITIATIVE_PHASES[config?.dataPointIndex];

        if (sectionName != undefined) {
          const state = app.getAppState();

          if (state.currentOE?.id == null) {
            return;
          }

          app.patchAppState({
            initiativeFilterData: {
              searchQueryFilter: "",
              yearFilter: "",
              phaseFilter: [sectionName],
              updateStatusFilter: [],
            },
          });

          app.navigate(
            generatePath(APP_ROUTES.operatingEntities, {
              oeId: state.currentOE.id,
              viewname: "initiatives",
            })
          );
        }
      },
    };
  });

  const impactHeader = view.addComponent({
    component: SectionHeader,
    layoutProps: {
      xs: 12,
      viewname: "dashboard",
    },
  });

  impactHeader.mapState(() => ({
    title: "Impact",
  }));

  const impactValueChartCard = view.addComponent({
    component: LineChartCard,
    layoutProps: {
      xs: 12,
      viewname: "dashboard",
    },
  });

  impactValueChartCard.mapState((appState) => {
    const aggregatedImpactValues: AggregatedImpactValues = appState
      .currentOEInitiatives?.aggregatedImpactValues || {
      automationData: [],
      processMiningData: [],
      totalImpactData: [],
      startQuarter: "",
      startYear: "",
    };
    const {
      automationData,
      processMiningData,
      totalImpactData,
      startQuarter,
      startYear,
    } = aggregatedImpactValues;

    return {
      initialQuarter: startQuarter,
      initialYear: startYear,
      title: "Line Chart",
      series: [
        {
          id: "automationData",
          label: "Automation Enabled",
          data: automationData,
          color: IN_COLORS.orchid,
          curve: "linear",
        },
        {
          id: "processMiningData",
          label: "Process Mining Enabled",
          data: processMiningData,
          color: setOpacity(IN_COLORS.navyBlue, 0.5),
          curve: "linear",
        },
        {
          id: "totalImpactData",
          label: "Total Impact",
          data: totalImpactData,
          color: IN_COLORS.forestGreen,
          curve: "linear",
        },
      ],
    };
  });
}
