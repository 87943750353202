import dayjs from "dayjs";
import quarterofyear from "dayjs/plugin/quarterOfYear";
import type {
  EditInitiativesResponse,
  ImpactByYearAndQuarter,
  Quarter,
} from "in-types";
import type { FieldError, Path } from "react-hook-form";

dayjs.extend(quarterofyear);

export type FormFieldErrors = {
  [key in Path<EditInitiativesResponse>]?: FieldError;
};

export const REQUIRED_ERROR = {
  type: "required",
  message: "This field is required",
} satisfies FieldError;

function getMaxLengthError(fieldName: string, maxLength: number) {
  return {
    type: "maxLength",
    message: `The ${fieldName} must be no longer than ${maxLength} characters.`,
  };
}

export function validateFormFields(
  formFields?: EditInitiativesResponse
): FormFieldErrors {
  if (!formFields) {
    return {};
  }

  const errors = {
    ...validateInitiateTabFields(formFields),
    ...validateImpactTabFields(formFields),
    ...validateEnablerTabFields(formFields),
  } satisfies FormFieldErrors;

  return errors;
}

function validateInitiateTabFields({
  initiativeName,
  initiativeDescription,
  initiativePhase,
  dates,
  function: functionField,
  lineOfBusiness,
  lineOfBusinessOther,
  implementationArm,
  implementationArmOther,
}: EditInitiativesResponse) {
  const errors: FormFieldErrors = {};

  if (!initiativeName) {
    errors.initiativeName = REQUIRED_ERROR;
  } else if (initiativeName.length > 64) {
    errors.initiativeName = getMaxLengthError("initiative name", 64);
  }

  if (initiativeDescription && initiativeDescription.length > 3000) {
    errors.initiativeDescription = getMaxLengthError("description", 3000);
  }

  if (!initiativePhase) {
    errors.initiativePhase = REQUIRED_ERROR;
  }

  if (initiativePhase !== "Idea / Backlog") {
    if (!dates?.startDate) {
      errors["dates.startDate"] = REQUIRED_ERROR;
    }

    if (!dates?.endDate) {
      errors["dates.endDate"] = REQUIRED_ERROR;
    }
  }

  if (dates?.startDate && dates?.endDate) {
    const startDate = dayjs(dates.startDate);
    const endDate = dayjs(dates.endDate);

    if (endDate.isBefore(startDate)) {
      errors["dates.endDate"] = {
        type: "required",
        message: "End Date must be after Start Date",
      };
    }
  }

  if (!functionField) {
    errors.function = REQUIRED_ERROR;
  }

  if (!lineOfBusiness?.length) {
    errors.lineOfBusiness = REQUIRED_ERROR;
  }

  if (lineOfBusiness?.includes("Other") && !lineOfBusinessOther) {
    errors.lineOfBusinessOther = REQUIRED_ERROR;
  }

  if (!implementationArm?.length) {
    errors.implementationArm = REQUIRED_ERROR;
  }

  if (implementationArm === "Other" && !implementationArmOther) {
    errors.implementationArmOther = REQUIRED_ERROR;
  }

  return errors;
}

function validateImpactTabFields(formFields: EditInitiativesResponse) {
  const {
    impactType,
    quantitativeImpactType,
    qualitativeImpactType,
    qualitativeImpactTypeOther,
  } = formFields;
  const errors: FormFieldErrors = {};

  if (!impactType?.length) {
    errors.impactType = REQUIRED_ERROR;
  }

  if (
    impactType?.includes("Quantitative") &&
    (!quantitativeImpactType || quantitativeImpactType.length === 0)
  ) {
    errors.quantitativeImpactType = REQUIRED_ERROR;
  }

  (quantitativeImpactType || []).forEach((type) => {
    const impact = formFields[`gross${type}Impact`];

    if (areImpactValuesMissing(impact?.planned, formFields)) {
      errors[`gross${type}Impact`] = {
        type: "required",
        message: "Please fill out missing impact values.",
      };
    }

    if (
      checkImpactValuesAreCumulative(impact?.planned) ||
      checkImpactValuesAreCumulative(impact?.actual)
    ) {
      errors[`gross${type}Impact`] = {
        type: "required",
        message:
          "Impact values must be cumulative over the duration of the initiative.",
      };
    }
  });

  if (qualitativeImpactType === "Other" && !qualitativeImpactTypeOther) {
    errors.qualitativeImpactTypeOther = REQUIRED_ERROR;
  }

  return errors;
}

function validateEnablerTabFields({
  initiativeEnablerFields,
}: EditInitiativesResponse) {
  const errors: FormFieldErrors = {};

  if (!initiativeEnablerFields) {
    return errors;
  }

  const {
    initiativeEnablers,
    automationComplexity,
    automationInvolvedSystem,
    automationMethodology,
    automationMethodologyOther,
    automationTechnicalSolution,
    automationSolutionImplementationProvider,
    automationUnderlyingPlatform,
    processMiningEnablementType,
    processMiningEnablementTypeOther,
  } = initiativeEnablerFields;

  if (initiativeEnablers?.includes("Automation")) {
    if (!automationComplexity) {
      errors["initiativeEnablerFields.automationComplexity"] = REQUIRED_ERROR;
    }
    if (!automationInvolvedSystem) {
      errors["initiativeEnablerFields.automationInvolvedSystem"] =
        REQUIRED_ERROR;
    }
    if (!automationMethodology?.length) {
      errors["initiativeEnablerFields.automationMethodology"] = REQUIRED_ERROR;
    }
    if (
      automationMethodology?.includes("Other") &&
      (!automationMethodologyOther || automationMethodologyOther.length === 0)
    ) {
      errors["initiativeEnablerFields.automationMethodologyOther"] =
        REQUIRED_ERROR;
    }
    if (!automationTechnicalSolution) {
      errors["initiativeEnablerFields.automationTechnicalSolution"] =
        REQUIRED_ERROR;
    }
    if (!automationSolutionImplementationProvider) {
      errors[
        "initiativeEnablerFields.automationSolutionImplementationProvider"
      ] = REQUIRED_ERROR;
    }
    if (!automationUnderlyingPlatform?.length) {
      errors["initiativeEnablerFields.automationUnderlyingPlatform"] =
        REQUIRED_ERROR;
    }
  }
  if (initiativeEnablers?.includes("Process Mining")) {
    if (!processMiningEnablementType?.length) {
      errors["initiativeEnablerFields.processMiningEnablementType"] =
        REQUIRED_ERROR;
    }

    if (
      processMiningEnablementType?.includes("Other") &&
      !processMiningEnablementTypeOther
    ) {
      errors["initiativeEnablerFields.processMiningEnablementTypeOther"] =
        REQUIRED_ERROR;
    }
  }

  return errors;
}

/**
 * Checks if any field of the given impact array has an empty or undefined value
 */
function areImpactValuesMissing(
  impactValues: ImpactByYearAndQuarter | undefined,
  formFields: EditInitiativesResponse
) {
  const { startDate, endDate } = formFields.dates ?? {};

  if (!startDate || !endDate || !impactValues) {
    return;
  }

  const start = dayjs(startDate);
  const end = dayjs(endDate);

  if (end.isBefore(start)) {
    return;
  }

  const startQuarter = start.quarter();
  const startYear = start.year();
  const endQuarter = end.quarter();
  const endYear = end.year();

  let quarter = startQuarter;
  let year = startYear;

  while (year <= endYear && quarter <= endQuarter) {
    if (
      impactValues?.[String(year)]?.[`Q${quarter}` as Quarter]?.value ==
      undefined
    ) {
      return true;
    }

    quarter++;

    if (quarter > 4) {
      quarter = 1;
      year++;
    }
  }

  return false;
}

/*
 * Checks the values of the changed year in the given Record.
 * Checks that values within a year are always incrementing.
 */
function checkImpactValuesAreCumulative(
  impactByYearAndQuarter?: ImpactByYearAndQuarter
): boolean {
  if (!impactByYearAndQuarter) {
    return false;
  }

  let lastValue: number | undefined;

  /** iterate over each impact by year, sorted */
  for (const [, impactByQuarter] of Object.entries(impactByYearAndQuarter).sort(
    ([aYear], [bYear]) => {
      return +aYear - +bYear;
    }
  )) {
    /** iterate over each impact by quarter, sorted by quarter asc */
    for (const impact of Object.values(impactByQuarter).sort(
      ({ quarter: a }, { quarter: b }) => a.localeCompare(b)
    )) {
      if (impact.value != null) {
        const value = Number(impact.value);

        if (lastValue != null && value < lastValue) {
          return true;
        }

        lastValue = value;
      }
    }
  }

  return false;
}
