import { INITIATIVE_ENABLER_TYPES, QUALITATIVE_IMPACT_TYPE_TO_ACTUAL_IMPACT_VALUE } from 'in-types';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import quarterofyear from 'dayjs/plugin/quarterOfYear';

var IN_COLORS = {
    deepBlue: "#003781",
    forestGreen: "#087C4C",
    green: "#00C853",
    grey: "#808080",
    lightBlue: "#94CADC",
    navyBlue: "#003781",
    orchid: "#A464FB",
    white: "#FFFFFF",
    yellow: "#FAB600",
};

var ENABLERS = {
    PROCESS_MINING: INITIATIVE_ENABLER_TYPES[0],
    AUTOMATION: INITIATIVE_ENABLER_TYPES[1],
    TOTAL: INITIATIVE_ENABLER_TYPES[2],
};

var FILTER_PARAMS = [
    "impactLead",
    "operatingEntity",
    "OERegion",
    "search",
    "updated",
];

var ADMIN_GROUP_NAME = "admin";

var PLANNED_IMPACT_PROPERTIES = [
    "grossEfficiencyImpactPlanned",
    "grossEffectivenessImpactPlanned",
    "grossGrowthImpactPlanned",
];
var ACTUAL_IMPACT_PROPERTIES = [
    "grossEfficiencyImpactActual",
    "grossEffectivenessImpactActual",
    "grossGrowthImpactActual",
];
var IMPACT_PROPERTIES = [
    "grossEfficiencyImpact",
    "grossEffectivenessImpact",
    "grossGrowthImpact",
];

var LOCK_RESOURCE_TEXTS = {
    resourceCurrentlyLocked: function (resource, email) {
        return "This ".concat(resource, " is locked to edit by \"").concat(email, "\"");
    },
};
var LOCK_RESOURCE_NAMES = {
    oeInitiatives: "oe-initiatives",
    oeComments: "oe-comments",
    oeImpactPlan: "oe-impact-plan",
};

var QUARTERS = ["Q1", "Q2", "Q3", "Q4"];

function convertCamelAndKebabCaseToTitle(str) {
    // Split the string by dots for nested objects
    var splittedString = str.split(".");
    // Handle camelCase and kebab-case
    var result = splittedString[splittedString.length - 1]
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Handle camelCase (add space before uppercase letters)
        .replace(/-/g, " - ") // Replace hyphens with spaces around them
        .replace(/\s+/g, " "); // Remove any extra spaces that may have been added
    // Capitalize the first letter of each word
    var capitalizedResult = result
        .split(" ")
        .map(function (word) { return word.charAt(0).toUpperCase() + word.slice(1); })
        .join(" ");
    return capitalizedResult;
}

function mapCommentsToYearAndQuarter(comments) {
    var commentsByYearAndQuarter = {};
    comments.forEach(function (comment) {
        var year = comment.year, quarter = comment.quarter;
        if (!commentsByYearAndQuarter[year]) {
            commentsByYearAndQuarter[year] = {};
        }
        commentsByYearAndQuarter[year][quarter] = comment;
    });
    return commentsByYearAndQuarter;
}
function mapCommentsToArray(comments) {
    return Object.values(comments)
        .map(function (commentsByQuarter) { return Object.values(commentsByQuarter); })
        .flat()
        .filter(function (comment) { return comment.content != null && comment.content !== ""; });
}

function countOccurrences(property, values, array) {
    var result = Object.fromEntries(values.map(function (value) { return [value, 0]; }));
    for (var _i = 0, _a = array !== null && array !== undefined ? array : []; _i < _a.length; _i++) {
        var item = _a[_i];
        var propertyValue = item === null || item === undefined ? undefined : item[property];
        for (var _b = 0, values_1 = values; _b < values_1.length; _b++) {
            var value = values_1[_b];
            if (typeof value === "string" &&
                (typeof propertyValue === "string" || Array.isArray(propertyValue)) &&
                propertyValue.includes(value)) {
                result[value]++;
            }
        }
    }
    return result;
}

function filterInitiatives(_a, initiatives) {
    var searchQueryFilter = _a.searchQueryFilter, yearFilter = _a.yearFilter, updateStatusFilter = _a.updateStatusFilter, phaseFilter = _a.phaseFilter;
    return initiatives.filter(function (initiative) {
        var _a;
        if (searchQueryFilter && searchQueryFilter !== "") {
            var search = searchQueryFilter.toLowerCase();
            if (!initiative.uid.toLowerCase().includes(search) &&
                !initiative.initiativeName.toLowerCase().includes(search) &&
                !((_a = initiative.initiativeOwner) === null || _a === undefined ? undefined : _a.toLowerCase().includes(search))) {
                return false;
            }
        }
        // Year filter
        if (yearFilter && yearFilter !== "") {
            var yearFilterNum = Number.parseInt(yearFilter);
            var startYear = initiative.startDate
                ? dayjs(initiative.startDate).year()
                : undefined;
            var endYear = initiative.endDate
                ? dayjs(initiative.endDate).year()
                : undefined;
            if (!startYear && !endYear) {
                return false;
            }
            if ((!startYear && endYear && endYear !== yearFilterNum) ||
                (startYear && !endYear && startYear !== yearFilterNum)) {
                return false;
            }
            if (startYear && endYear) {
                if (startYear > yearFilterNum || endYear < yearFilterNum) {
                    return false;
                }
            }
        }
        if (updateStatusFilter &&
            updateStatusFilter.length > 0 &&
            initiative.updateStatus &&
            !updateStatusFilter.includes(initiative.updateStatus)) {
            return false;
        }
        if (phaseFilter &&
            phaseFilter.length > 0 &&
            initiative.initiativePhase &&
            !phaseFilter.includes(initiative.initiativePhase)) {
            return false;
        }
        return true;
    });
}

dayjs.extend(utc);
dayjs.extend(timezone);
/** formats a given `string` or `Date` into a `string` with the format `YYYY-MM-DD"`
 * and adjusts it to the current timezone */
function formatDate(date, format) {
    if (format === undefined) { format = "YYYY-MM-DD"; }
    if (!date) {
        return "";
    }
    var currentTimezone = dayjs.tz.guess();
    return dayjs(date).tz(currentTimezone).format(format);
}

dayjs.extend(quarterofyear);
function getQuarterAndYear(date, offset) {
    if (date === undefined) { date = new Date(); }
    if (offset === undefined) { offset = 0; }
    // get the dayjs date & quarter of the input date
    var dayjsDate = dayjs(date);
    var quarter = dayjsDate.quarter();
    // calculate the quarter and year based on the input date and the offset
    var calculatedDate = dayjsDate.quarter(quarter + offset);
    var calculatedYear = calculatedDate.year();
    var calculatedQuarter = calculatedDate.quarter();
    return ["Q".concat(calculatedQuarter), calculatedYear.toString()];
}

function hasImpactValue(_a) {
    var _b;
    var impactValues = _a.impactValues, quarter = _a.quarter, year = _a.year;
    return ((_b = impactValues === null || impactValues === undefined ? undefined : impactValues.some(function (impact) {
        return impact.year === year &&
            impact.quarter === quarter &&
            impact.value != null;
    })) !== null && _b !== undefined ? _b : false);
}

dayjs.extend(quarterofyear);
function getUpdateStatus(initiative) {
    var _a, _b, _c, _d;
    // if the initiative is in the "Idea / Backlog" or "Done" phase, it's considered "completed"
    if (initiative.initiativePhase === "Idea / Backlog" ||
        initiative.initiativePhase === "Done") {
        return "completed";
    }
    // if the initiative's impact type does not include "Quantitative", it's considered "completed"
    if (!((_a = initiative.impactType) === null || _a === undefined ? undefined : _a.includes("Quantitative"))) {
        return "completed";
    }
    // if the initiative's quantitative impact type doesn't exist or is empty, it's considered "completed"
    if (!((_b = initiative.quantitativeImpactType) === null || _b === undefined ? undefined : _b.length)) {
        return "completed";
    }
    // initialize dayjs dates for easy comparison
    var currentDate = dayjs(new Date());
    var endDate = dayjs(new Date((_c = initiative === null || initiative === undefined ? undefined : initiative.endDate) !== null && _c !== undefined ? _c : ""));
    var startDate = dayjs(new Date((_d = initiative === null || initiative === undefined ? undefined : initiative.startDate) !== null && _d !== undefined ? _d : ""));
    // if the current date is before the start date or after the end date, it's considered "completed"
    if (currentDate.year() < startDate.year() ||
        (currentDate.year() === startDate.year() &&
            currentDate.quarter() < startDate.quarter()) ||
        currentDate.year() > endDate.year() ||
        (currentDate.year() === endDate.year() &&
            currentDate.quarter() > endDate.quarter())) {
        return "completed";
    }
    // initialize the current and previous cyle's quarters and years
    var _e = getQuarterAndYear(currentDate.toDate()), currentQuarter = _e[0], currentYear = _e[1];
    var _f = getQuarterAndYear(currentDate.toDate(), -1), previousCylceQuarter = _f[0], previousCylceQuarterYear = _f[1];
    // if all the required "actuals" impact values for the current quarter and year exist, it's considered "completed"
    if (initiative.quantitativeImpactType.every(function (impactType) {
        return hasImpactValue({
            impactValues: initiative[QUALITATIVE_IMPACT_TYPE_TO_ACTUAL_IMPACT_VALUE[impactType]],
            quarter: currentQuarter,
            year: currentYear,
        });
    })) {
        return "completed";
    }
    // if all the required "actuals" impact values for the previous quarter and year exist, it's considered "pending"
    if (initiative.quantitativeImpactType.every(function (impactType) {
        return hasImpactValue({
            impactValues: initiative[QUALITATIVE_IMPACT_TYPE_TO_ACTUAL_IMPACT_VALUE[impactType]],
            quarter: currentQuarter,
            year: currentYear,
        }) ||
            hasImpactValue({
                impactValues: initiative[QUALITATIVE_IMPACT_TYPE_TO_ACTUAL_IMPACT_VALUE[impactType]],
                quarter: previousCylceQuarter,
                year: previousCylceQuarterYear,
            });
    })) {
        return "pending";
    }
    // if the current date is in the same quarter and year as the start date, it's considered "pending"
    // this is because the initiative is still in the first quarter of its lifecycle
    if (currentDate.year() === startDate.year() &&
        currentDate.quarter() === startDate.quarter()) {
        return "pending";
    }
    // else it's considered "overdue"
    return "overdue";
}

var FIELDS_TO_OMIT = ["createdBy", "updatedBy"];
var getInitiativesResponseForStrapiByInitiativeForFrontend = function (initiative) {
    FIELDS_TO_OMIT.forEach(function (fieldToOmit) { return delete initiative[fieldToOmit]; });
    return initiative;
};

function sortString(a, b, direction) {
    a = a.toLowerCase();
    b = b.toLowerCase();
    if (a < b) {
        if (direction === "DESC") {
            return 1;
        }
        return -1;
    }
    if (a > b) {
        if (direction === "DESC") {
            return -1;
        }
        return 1;
    }
    return 0;
}
function sortByDate(a, b, direction) {
    if (!a) {
        if (direction === "DESC") {
            return 1;
        }
        return -1;
    }
    if (!b) {
        if (direction === "DESC") {
            return -1;
        }
        return 1;
    }
    var dateA = dayjs(a);
    var dateB = dayjs(b);
    if (dateA.isSame(dateB, "day")) {
        return 0;
    }
    if (dateA.isAfter(dateB, "day")) {
        if (direction === "DESC") {
            return -1;
        }
        return 1;
    }
    if (direction === "DESC") {
        return 1;
    }
    return -1;
}
var PHASES = {
    "Idea / Backlog": 0,
    Planned: 1,
    "In Progress": 2,
    Done: 3,
};
var UPDATE_STATUS = {
    completed: 0,
    pending: 1,
    overdue: 2,
};
var SORT_BY_KEY = {
    uid: function (a, b, direction) {
        var uidA = Number(a.uid.substring(4));
        var uidB = Number(b.uid.substring(4));
        if (direction === "ASC") {
            return uidA - uidB;
        }
        return uidB - uidA;
    },
    initiativeName: function (a, b, direction) {
        return sortString(a.initiativeName, b.initiativeName, direction);
    },
    startDate: function (a, b, direction) {
        return sortByDate(a.startDate, b.startDate, direction);
    },
    endDate: function (a, b, direction) { return sortByDate(a.endDate, b.endDate, direction); },
    lastUpdatedAt: function (a, b, direction) {
        return sortByDate(a.lastUpdatedAt, b.lastUpdatedAt, direction);
    },
    impactValue: function (a, b, direction) {
        var _a, _b;
        var impactA = (_a = a.impactValue) !== null && _a !== undefined ? _a : Infinity;
        var impactB = (_b = b.impactValue) !== null && _b !== undefined ? _b : Infinity;
        return direction === "ASC" ? impactA - impactB : impactB - impactA;
    },
    initiativeOwner: function (a, b, direction) { var _a, _b; return sortString((_a = a.initiativeOwner) !== null && _a !== undefined ? _a : "", (_b = b.initiativeOwner) !== null && _b !== undefined ? _b : "", direction); },
    initiativePhase: function (a, b, direction) {
        var phaseOrderA = a.initiativePhase
            ? PHASES[a.initiativePhase]
            : Infinity;
        var phaseOrderB = b.initiativePhase
            ? PHASES[b.initiativePhase]
            : Infinity;
        return direction === "ASC"
            ? phaseOrderA - phaseOrderB
            : phaseOrderB - phaseOrderA;
    },
    updateStatus: function (a, b, direction) {
        var statusB = b.updateStatus ? UPDATE_STATUS[b.updateStatus] : Infinity;
        var statusA = a.updateStatus ? UPDATE_STATUS[a.updateStatus] : Infinity;
        return direction === "ASC" ? statusA - statusB : statusB - statusA;
    },
};
function sortInitiatives(_a, initiatives) {
    var key = _a.key, direction = _a.direction;
    return initiatives.sort(function (a, b) {
        var sortingFn = SORT_BY_KEY[key];
        if (!sortingFn) {
            return 0;
        }
        return sortingFn(a, b, direction);
    });
}

function sortInitiativesByUid(initiatives) {
    if (!initiatives) {
        return [];
    }
    return initiatives.sort(function (initiativeA, initiativeB) {
        var uidA = Number(initiativeA.uid.substring(4));
        var uidB = Number(initiativeB.uid.substring(4));
        return uidA - uidB;
    });
}

export { ACTUAL_IMPACT_PROPERTIES, ADMIN_GROUP_NAME, ENABLERS, FILTER_PARAMS, IMPACT_PROPERTIES, IN_COLORS, LOCK_RESOURCE_NAMES, LOCK_RESOURCE_TEXTS, PLANNED_IMPACT_PROPERTIES, QUARTERS, convertCamelAndKebabCaseToTitle, countOccurrences, filterInitiatives, formatDate, getInitiativesResponseForStrapiByInitiativeForFrontend, getQuarterAndYear, getUpdateStatus, hasImpactValue, mapCommentsToArray, mapCommentsToYearAndQuarter, sortInitiatives, sortInitiativesByUid };
