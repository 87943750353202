import { type App, type Plugin, Route, View } from "@pimo/pimo-app-builder";
import {
  ArtefactsProgressCard,
  DonutChartCard,
  GridLayout,
  InformationCard,
  NumberCard,
  SectionHeader,
  TabsLayoutProps,
  TitleCard,
} from "@pimo/pimo-components";
import { AggregatedImpactValues, INITIATIVE_PHASES } from "in-types";
import { ENABLERS, getQuarterAndYear, IN_COLORS } from "in-utils";

import { LineChartCard } from "../../components/line-chart-card/line-chart-card.tsx";
import { InAppState } from "../app.tsx";
import { APP_ROUTES } from "../constants.ts";
import { fetchOEs } from "../helpers/fetch-helpers.ts";
import { isUserAdmin } from "../helpers/is-user-admin.ts";
import { convertToMillion, setOpacity } from "../utils/index.ts";

export class DashboardPlugin implements Plugin<InAppState, InAppState> {
  route?: Route;
  private view?: View<InAppState, TabsLayoutProps>;
  private app?: App<InAppState>;

  onRegister(app: App<InAppState>): void {
    this.app = app;
    this.view = app.createView({
      name: "Dashboard",
      layout: new GridLayout(),
    });
    this.buildRoute();
    this.buildPage();
  }

  private buildPage() {
    if (!this.view) {
      return;
    }
    const pageTitle = this.view.addComponent({
      component: TitleCard,
      layoutProps: { xs: 12 },
    });

    pageTitle.mapState(() => {
      const [currentQuarter, currentYear] = getQuarterAndYear();
      return {
        title: `Group Dashboard - Impact Navigator Tool (As of: ${currentQuarter} ${currentYear})`,
      };
    });

    const topCardsLayoutProps = {
      xs: 6,
      md: 12 / 3,
      lg: 12 / 6,
    };

    const totalImpactValueCard = this.view.addComponent({
      component: NumberCard,
      layoutProps: topCardsLayoutProps,
    });

    totalImpactValueCard.mapState((appState) => {
      const totalImpact = appState.groupOverview?.groupTotalImpactValue;
      const totalImpactValue = totalImpact?.value ?? 0;
      const targetYear = totalImpact?.targetYear ?? "";

      return {
        title: "Total Impact EOY " + targetYear,
        number: convertToMillion(totalImpactValue) + " Mn",
        iconSrc: "checklist.svg",
      };
    });

    const overallProgressCard = this.view.addComponent({
      component: ArtefactsProgressCard,
      layoutProps: topCardsLayoutProps,
    });

    overallProgressCard.mapState((appState) => {
      const updateStatus = appState.groupOverview?.groupUpdateStatus;
      const progress = parseFloat(
        (
          ((updateStatus?.completed ?? 0) / (updateStatus?.total ?? 1)) *
          100
        ).toFixed()
      );

      return {
        cardIcon: "checklist.svg",
        cardTitle: "Overall Progress",
        color: IN_COLORS.lightBlue,
        displayValue: `${progress} %`,
        value: progress,
      };
    });

    const totalInitiativesCard = this.view.addComponent({
      component: NumberCard,
      layoutProps: topCardsLayoutProps,
    });

    totalInitiativesCard.mapState((appState) => {
      return {
        number: `${appState.groupOverview?.numberOfInitiatives ?? ""}`,
        title: "Total Initiatives",
      };
    });

    const updatesCompletedCard = this.view.addComponent({
      component: NumberCard,
      layoutProps: topCardsLayoutProps,
    });

    updatesCompletedCard.mapState((appState) => {
      return {
        number: `${appState.groupOverview?.groupUpdateStatus?.completed ?? ""}`,
        title: "Initiative Updates Complete",
      };
    });

    const updatePendingCard = this.view.addComponent({
      component: NumberCard,
      layoutProps: topCardsLayoutProps,
    });

    updatePendingCard.mapState((appState) => {
      return {
        number: `${appState.groupOverview?.groupUpdateStatus?.pending ?? ""}`,
        title: "Initiative Updates Pending",
      };
    });

    const updatesOverdueCard = this.view.addComponent({
      component: NumberCard,
      layoutProps: topCardsLayoutProps,
    });

    updatesOverdueCard.mapState((appState) => {
      return {
        number: `${appState.groupOverview?.groupUpdateStatus?.overdue ?? ""}`,
        title: "Initiative Updates Overdue",
      };
    });

    const enablerCard = this.view.addComponent({
      component: InformationCard,
      layoutProps: {
        xs: 12,
        md: 12 / 2,
      },
    });

    enablerCard.mapState((appState) => {
      const groupEnablerOverview = appState.groupOverview?.groupEnablerOverview;

      return {
        title: "Enabler",
        progressBars: [
          {
            title: ENABLERS.AUTOMATION,
            progress:
              groupEnablerOverview?.[ENABLERS.AUTOMATION]?.progress ?? 0,
            actualValue:
              groupEnablerOverview?.[ENABLERS.AUTOMATION]?.value ?? "",
          },
          {
            title: ENABLERS.PROCESS_MINING,
            progress:
              groupEnablerOverview?.[ENABLERS.PROCESS_MINING]?.progress ?? 0,
            actualValue:
              groupEnablerOverview?.[ENABLERS.PROCESS_MINING]?.value ?? "",
          },
        ],
      };
    });

    const initiativePhasesCard = this.view.addComponent({
      component: DonutChartCard,
      layoutProps: {
        xs: 12,
        md: 12 / 2,
      },
    });

    initiativePhasesCard.mapState((appState) => {
      const groupPhasesOverview =
        appState.groupOverview?.groupPhasesOverview || {};

      return {
        title: "Initiative Phases",
        series: groupPhasesOverview
          ? Object.values(
              groupPhasesOverview as { [initiativePhase: string]: number }
            )
          : [0, 0, 0, 0],
        backgroundColor: [
          IN_COLORS.grey,
          IN_COLORS.yellow,
          IN_COLORS.green,
          IN_COLORS.deepBlue,
        ],
        labels: INITIATIVE_PHASES,
        toolTipEnabled: true,
        toolTipLabels: INITIATIVE_PHASES,
      };
    });

    const impactHeader = this.view.addComponent({
      component: SectionHeader,
      layoutProps: { xs: 12 },
    });

    impactHeader.mapState(() => ({
      title: "Impact",
    }));

    const impactValueChartCard = this.view.addComponent({
      component: LineChartCard,
      layoutProps: { xs: 12 },
    });

    impactValueChartCard.mapState((appState) => {
      const appendSpaces = (text: string) => text + "   ";
      const groupImpactValues: AggregatedImpactValues = appState.groupOverview
        ?.groupImpactValues || {
        automationData: [],
        processMiningData: [],
        totalImpactData: [],
        startQuarter: "",
        startYear: "",
      };
      const {
        automationData,
        processMiningData,
        totalImpactData,
        startQuarter,
        startYear,
      } = groupImpactValues;

      return {
        initialQuarter: startQuarter,
        initialYear: startYear,
        title: "Line Chart",
        series: [
          {
            id: "automationData",
            label: appendSpaces("Automation Enabled"),
            data: automationData,
            color: IN_COLORS.orchid,
            curve: "linear",
          },
          {
            id: "processMiningData",
            label: appendSpaces("Process Mining Enabled"),
            data: processMiningData,
            color: setOpacity(IN_COLORS.navyBlue, 0.5),
            curve: "linear",
          },
          {
            id: "totalImpactData",
            label: appendSpaces("Total Impact"),
            data: totalImpactData,
            color: IN_COLORS.forestGreen,
            curve: "linear",
          },
        ],
      };
    });
  }

  private buildRoute() {
    if (!this.app || !this.view) {
      return;
    }

    this.route = this.app.createRoute({
      path: `${APP_ROUTES.dashboard}`,
      view: this.view,
    });

    this.route?.on("load", async () => {
      if (!this.app) {
        return;
      }

      const { isLoggedIn, userProfile } = this.app.getAppState();

      if (!isLoggedIn) {
        this.app.navigate(APP_ROUTES.login);
        return;
      }

      if (!isUserAdmin(userProfile)) {
        this.app.navigate(APP_ROUTES.overview);
        return;
      }

      const groupOverview = await fetchOEs();

      this.app.patchAppState({
        groupOverview,
        isLoading: false,
      });
    });
  }
}
