import { FormHelperText, Grid, TextField, Typography } from "@mui/material";
import type {
  AdditionalFormFieldProps,
  BaseFormFieldDefinition,
  FormFieldType,
} from "@pimo/pimo-components/src/lib/pimo-components/pimo-form/form-field";
import dayjs from "dayjs";
import quarterofyear from "dayjs/plugin/quarterOfYear";
import type {
  DataItem,
  EditInitiativesResponse,
  PlannedAndActualImpact,
  QuantitativeImpactType,
  Quarter,
  UpdateStatus,
} from "in-types";
import { getQuarterAndYear } from "in-utils";
import { type ChangeEvent } from "react";

dayjs.extend(quarterofyear);

// eslint-disable-next-line react-refresh/only-export-components
export const INITIATIVE_IMPACT_TABLE_TYPE = "initiative_impact_table" as const;

type QuantitativeImpactField =
  | "grossEfficiencyImpact"
  | "grossGrowthImpact"
  | "grossEffectivenessImpact";

export type InitiatveImpactTableDefinition = BaseFormFieldDefinition<
  typeof INITIATIVE_IMPACT_TABLE_TYPE
> & {
  name: QuantitativeImpactField;
  type: typeof INITIATIVE_IMPACT_TABLE_TYPE;
  value: PlannedAndActualImpact;
  formData: EditInitiativesResponse & {
    unlockImpactValues?: boolean;
    updateStatus: UpdateStatus;
  };
};

export type GridField = Omit<BaseFormFieldDefinition, "type"> & {
  value?: string;
  quarter?: number;
  year?: number;
  minValue?: number;
};
export type GridLabel = { label: string };

const FLOAT_REGEX = /^-?\d*?(?:[.]\d*?)?$/;

const ERROR_COLOR = "#d32f2f";

const BORDER_COLORS = {
  completed: "#00c853",
  pending: "#f9a825",
  overdue: "#f44336",
};

const FIELD_NAME_TO_LABEL = {
  grossEfficiencyImpact: "Efficiency",
  grossGrowthImpact: "Growth",
  grossEffectivenessImpact: "Effectiveness",
} as const satisfies Record<QuantitativeImpactField, QuantitativeImpactType>;

export function InitiativeImpactTable({
  register,
  name,
  formData,
  errorMessage,
  value,
}: InitiatveImpactTableDefinition & AdditionalFormFieldProps) {
  // Force fields to update on change
  const [currentQuarter, currentYear] = getQuarterAndYear();
  const { updateStatus: initiativeUpdateStatus, dates } = formData;

  const startDate = dates?.startDate ? dayjs(dates?.startDate) : dayjs();
  const endDate = dates?.endDate ? dayjs(dates?.endDate) : dayjs();
  const startQuarter = startDate.quarter();
  const endQuarter = endDate.quarter();

  if (endDate.isBefore(startDate)) {
    return null;
  }

  const { planned, actual } = value
    ? value
    : ({ planned: {}, actual: {} } as PlannedAndActualImpact);
  const rows: {
    layoutProps?: Record<string, unknown>;
    fields: (GridField | GridLabel)[];
  }[] = [];

  for (let i = startDate.year(); i <= endDate.year(); i++) {
    rows.push(
      ...getFieldsForQuarter({
        values: {
          planned,
          actual,
        },
        quarter: {
          min: i === startDate.year() ? startQuarter : 1,
          max: i === endDate.year() ? endQuarter : 4,
        },
        year: i,
      })
    );
  }

  const { onChange: onGridChange } = register(name);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value !== "" && !FLOAT_REGEX.test(value)) {
      return;
    }

    // an example value would be: `Q2/2024/planned`
    const [quarter, year, type] = event.target.name.split("/") as [
      Quarter,
      string,
      "planned" | "actual",
    ];
    const objectToChange = type === "planned" ? planned : actual;

    if (objectToChange[year] == undefined) {
      objectToChange[year] = {
        [quarter]: {
          year,
          quarter,
          value,
        },
      } as Record<Quarter, DataItem>;
    } else {
      objectToChange[year][quarter] = {
        year,
        quarter,
        value,
      };
    }

    void onGridChange({
      target: {
        value: {
          actual,
          planned,
        },
        name,
      },
    });
  };

  return (
    <Grid
      alignItems="center"
      container
      item
      justifyContent="center"
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography variant="h5" component="h2">
          {FIELD_NAME_TO_LABEL[name]} (cumulative in k EUR)
        </Typography>
        {errorMessage && (
          <Typography variant="h5" component="h2">
            <FormHelperText sx={{ color: ERROR_COLOR }}>
              {errorMessage}
            </FormHelperText>
          </Typography>
        )}
      </Grid>
      {rows?.map((row, rowIndex) => {
        const rowUpdateStatus: UpdateStatus | undefined = getRowUpdateStatus(
          row,
          currentQuarter,
          currentYear
        );

        return row.fields.map((field, fieldIndex) => {
          if (isFormFieldDefinition(field)) {
            const disabled = formData.unlockImpactValues
              ? false
              : shouldFieldBeDisabled(field, startDate.year());

            return (
              <Grid
                key={field.name}
                xs={12 / row.fields.length}
                item
                {...row.layoutProps}
              >
                <TextField
                  disabled={disabled}
                  fullWidth
                  name={field.name}
                  onChange={onChange}
                  // Prevent changing value when scrolling through the form
                  InputProps={{
                    inputProps: {
                      onWheel: (e) => e.currentTarget.blur(),
                    },
                  }}
                  placeholder="k Euro"
                  sx={getCellStyle(
                    field,
                    rowUpdateStatus,
                    initiativeUpdateStatus,
                    errorMessage != null
                  )}
                  type="text"
                  value={field.value ?? ""}
                />
              </Grid>
            );
          }

          return (
            <Grid
              item
              key={`${field.label}-${rowIndex}-${fieldIndex}`}
              xs={12 / row.fields.length}
              {...row.layoutProps}
            >
              {field.label}
            </Grid>
          );
        });
      })}
    </Grid>
  );
}

/**
 * Determines if a grid field cell should get a border color. The border Color of the actual fields is determined by the rowUpdateStatus or initiativeUpdateStatus.
 * These are the rules by which it decides if and which border color a grid cell gets:
 * - If its a cell of the planned row:
 *   If the form field has an error (planned values are missing) and the field has no value
 *   -> red border
 * - If its a cell of the actual row:
 *   - If the field has NO value and its quarter and year are the before or equal to the current date
 *     -> assign the border color according to row- or initiativeUpdateStatus (should be orange or red)
 *   - If the field has a value and its quarter and year are equal to the current one
 *     -> assign the border color according to row- or initiativeUpdateStatus (should be green)
 */
function getCellStyle(
  field: GridField,
  rowUpdateStatus: UpdateStatus | undefined,
  initiativeUpdateStatus: UpdateStatus,
  hasError = false
) {
  const isActual = field.name.includes("actual");
  const [currentQuarter, currentYear] = getQuarterAndYear();
  const borderColor = field.value
    ? BORDER_COLORS["completed"]
    : BORDER_COLORS[rowUpdateStatus ?? initiativeUpdateStatus];

  const isCurrentQuarter =
    `Q${field.quarter ?? ""}` === currentQuarter &&
    field.year === Number.parseInt(currentYear);
  const isCurrentQuarterOrBefore =
    `Q${field.quarter ?? ""}` <= currentQuarter &&
    field.year === Number.parseInt(currentYear);

  // if the form field has an error set, mark all planned fields without a value
  if (!isActual && hasError && (!field.value || field.value === "")) {
    return {
      fieldSet: {
        borderWidth: "1.5px",
        borderRadius: "5px",
        borderColor: ERROR_COLOR,
      },
    };
  }

  // if the value of an actual field is null and its
  // represented quarter is the current one or before. (This will result in orange or red border color)
  // or
  // if the value of an actual field is NOT null and its quarter is the current one.
  // (This will result in green border color)
  return ((field.value == null || field.value === "") &&
    isCurrentQuarterOrBefore &&
    isActual) ||
    (field.value != null && field.value !== "" && isCurrentQuarter && isActual)
    ? {
        fieldSet: {
          borderWidth: "1.5px",
          borderRadius: "5px",
          borderColor,
        },
      }
    : { fieldSet: {} };
}

function getRowUpdateStatus(
  row: {
    layoutProps?: Record<string, unknown>;
    fields: (GridField | GridLabel)[];
  },
  currentQuarter: string,
  currentYear: string
) {
  let rowUpdateStatus: UpdateStatus | undefined;

  for (let i = 0; i <= +currentQuarter[1]; i++) {
    const field = row.fields.find((field) => {
      return (
        isFormFieldDefinition(field) &&
        field.quarter === i &&
        field.year === Number(currentYear) &&
        field.name.includes("actual")
      );
    }) as GridField;

    if (!field) {
      continue;
    }

    if ((field && field.value == null) || field.value === "") {
      if (
        field.quarter === +currentQuarter[1] &&
        rowUpdateStatus !== "overdue"
      ) {
        rowUpdateStatus = "pending";
      } else {
        rowUpdateStatus = "overdue";
      }
    }
  }
  return rowUpdateStatus;
}

function shouldFieldBeDisabled(
  { year, quarter, name }: GridField,
  initiativeStartYear: number
) {
  const [currQ, currY] = getQuarterAndYear();
  const currentQuarter = Number.parseInt(currQ[1]);
  const currentYear = Number.parseInt(currY);
  const isPlanned = name.includes("planned");

  if (!year || !quarter) {
    return false;
  }

  // Edge case: If the initiative started in the current year, unlock all fields for current year.
  if (initiativeStartYear === currentYear && initiativeStartYear === year) {
    return false;
  }

  // Planned values in future years can be edited
  if (isPlanned && year > currentYear) {
    return false;
  }

  // Actual value in the current quarter and the future
  if (
    !isPlanned &&
    (year > currentYear || (year === currentYear && quarter >= currentQuarter))
  ) {
    return false;
  }

  // If its the first quarter of the year, the last quarter of the previous year should still be editable.
  if (
    !isPlanned &&
    year === currentYear - 1 &&
    currentQuarter === 1 &&
    quarter === 4
  ) {
    return false;
  }

  return true;
}

type GetFieldsForQuarterOptions = {
  quarter: {
    min: number;
    max: number;
  };
  values: PlannedAndActualImpact;
  year: number;
};

type GetFieldsForQuarterReturn = {
  layoutProps?: Record<string, unknown>;
  fields: (GridField | GridLabel)[];
}[];

function getFieldsForQuarter({
  quarter: { min, max },
  values: { actual, planned },
  year,
}: GetFieldsForQuarterOptions): GetFieldsForQuarterReturn {
  const fields = [
    { label: `Q1/${year}`, year, quarter: 1 },
    { label: `Q2/${year}`, year, quarter: 2 },
    { label: `Q3/${year}`, year, quarter: 3 },
    { label: `Q4/${year}`, year, quarter: 4 },
  ];

  const headerRow: (GridField | GridLabel)[] = [];
  const plannedRow: (GridField | GridLabel)[] = [];
  const actualRow: (GridField | GridLabel)[] = [];

  for (const field of fields) {
    headerRow.push(field);

    if (field.quarter < min || field.quarter > max) {
      plannedRow.push({ label: "" });
      actualRow.push({ label: "" });

      continue;
    }

    plannedRow.push({
      name: `${field.label}/planned`,
      quarter: field.quarter,
      year: field.year,
      value: String(
        planned?.[field.year]?.[`Q${field.quarter}` as Quarter]?.value ?? ""
      ),
    });
    actualRow.push({
      name: `${field.label}/actual`,
      quarter: field.quarter,
      year: field.year,
      value: String(
        actual?.[field.year]?.[`Q${field.quarter}` as Quarter]?.value ?? ""
      ),
    });
  }

  return [
    { fields: [{ label: "" }, ...headerRow] },
    { fields: [{ label: "Planned*" }, ...plannedRow] },
    {
      fields: [{ label: "Actual / Forecast*" }, ...actualRow],
      layoutProps: { mb: 5 },
    },
  ];
}

function isFormFieldDefinition(
  element: GridField | GridLabel
): element is Omit<BaseFormFieldDefinition<FormFieldType>, "type"> {
  return !!(element as BaseFormFieldDefinition<FormFieldType>).name;
}
