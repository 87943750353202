import { Grid } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { OeOverview } from "in-types";

import OEsStatusTableRow from "./oes-status-table-row.js";

export type OEsStatusTableEventNames = "oes-status-table:oe-click";
export type OEsStatusTableEventPayload = { id: string };

export const OEsStatusTable: PimoReactComponent<
  {
    oes: OeOverview[];
    targetYear?: string;
  },
  OEsStatusTableEventNames,
  OEsStatusTableEventPayload
> = ({ oes, targetYear, fireEvent }) => {
  return (
    <Grid container spacing={1}>
      {oes.map((oe) => (
        <Grid item xs={12} key={oe.id}>
          <OEsStatusTableRow
            oe={oe}
            targetYear={targetYear}
            fireEvent={fireEvent}
          />
        </Grid>
      ))}
    </Grid>
  );
};
